import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[(_vm.$store.getters.error)?[_c(VContainer,[_c(VFlex,{attrs:{"xs6":""}},[_c('p',[_vm._v("Something went wrong.")]),_c(VBtn,{on:{"click":_vm.initialize}},[_vm._v(" Retry ")])],1)],1)]:[(!_vm.initialized)?_c(VContainer,{staticClass:"d-flex flex-column justify-space-between",staticStyle:{"height":"70vh"}},[_c(VImg,{staticStyle:{"width":"50%","height":"100%","margin":"auto","max-width":"150px"},attrs:{"contain":"","src":"/img/logo-600x363.png","lazy-src":"/img/logo-124x80.png"}}),_c(VProgressCircular,{staticStyle:{"margin":"auto"},attrs:{"indeterminate":"","color":"primary"}})],1):_c(VRow,[_c('Navagation'),_c(VMain,[(_vm.$store.getters.loading)?_c(VProgressLinear,{attrs:{"app":"","indeterminate":"","color":"#b83336"}}):_vm._e(),_c('router-view')],1)],1)],_c('SnackBar'),_c('Alertbox',{ref:"alertbox"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }