<template>
  <v-container
    fluid
  >
    <!-- ERROR PAGE WHEN INITIAL FETCH FAILS -->
    <template v-if="$store.getters.error">
      <v-container>
        <v-flex xs6>
          <p>Something went wrong.</p>
          <v-btn @click="initialize">
            Retry
          </v-btn>
        </v-flex>
      </v-container>
    </template>

    <!-- APPLICATION MAIN -->
    <template
      v-else
    >
      <v-container
        v-if="!initialized"
        style="height:70vh"
        class="d-flex flex-column justify-space-between"
      >
        <v-img
          contain
          style="width:50%;height:100%;margin:auto;max-width:150px;"
          src="/img/logo-600x363.png"
          lazy-src="/img/logo-124x80.png"
        />
        <v-progress-circular
          indeterminate
          color="primary"
          style="margin:auto"
        ></v-progress-circular>
      </v-container>
      <v-row v-else>
        <!-- HEADER -->
        <Navagation />
        <!-- Router view    /app/*     -->
        <v-main>
          <v-progress-linear
            v-if="$store.getters.loading"
            app
            indeterminate
            color="#b83336"
          />
          <!-- MAIN ROUTER CONTENT -->
          <router-view></router-view>
        </v-main>
      </v-row>
    </template>

    <!-- GLOBAL COMPONENTS -->
    <SnackBar />
    <Alertbox ref="alertbox" />
  </v-container>
</template>
<script>
import Navagation from './navigation/index.vue';
import Alertbox from './alert/alert.vue';
import SnackBar from './snackbar/snackbar.vue';
import Timer from '../plugins/timer.js';

export default {
  name: 'IndexPg',
  components: {
    Navagation,
    Alertbox,
    SnackBar,
  },
  data: () => {
    return {
      initialized: false,
      lastWeekModal: false,
      resumeTimer: null,
      resumeWaitTimeInMS: 5 * 60 * 1000, // x * 60 * 1000, x = minutes
    };
  },
  computed: {
    lastLocation() {
      if (this.$store.getters.user.username) {
        return localStorage.getItem(`${this.$store.getters.user.username}:lastLocation`) || '/';
      }
      return '/';
    },
  },
  created() {
    this.initialize();
    this.addNetworkListeners();
  },
  updated() {
    return !this.$store.getters.user.username && this.$router.push('/login').catch(() => {});
  },
  methods: {
    ///////////////////////////
    // ONLINE/OFFLINE HANDLING
    ///////////////////////////
    addNetworkListeners() {
      document.addEventListener('online', this.netStatOnline, false);
      document.addEventListener('offline', this.netStatOffline, false);
      document.addEventListener('resume', this.onResume, false);
    },
    netStatOnline() {
      this.updateStatus(true);
    },
    netStatOffline() {
      this.updateStatus(false);
    },
    discernNetStat() {
      // Returns true if online, false if offline
      return navigator.connection.type !== 'none';
    },
    updateStatus(status) {
      // Status is true, if online, and false, if offline.
      const netStat = this.$store.getters.online;
      // Ignores setting network status if it will make no change
      if (status !== netStat) {
        this.$store.dispatch('updateStatus', status);
      }
    },
    ///////////////////////
    // MAIN INIT FUNC
    ///////////////////////
    async initialize() {
      this.initialized = false;
      if (this.$route.path !== this.lastLocation
          && this.lastLocation !== '/'
          && this.lastLocation !== '/index.html'
          && this.lastLocation !== 'index.html'
      ) {
        this.$router.push(this.lastLocation).catch(() => {});
      }

      this.$store.dispatch('setNavigation', { display: false });
      const response = await this.$store.dispatch('getUser');
      if (response) {
        const initialized = await this.$store.dispatch('initialize');
        if (initialized) {
          this.checkEntriesForLastWeek();
        }
      }
      this.initialized = true;
    },
    async onResume() {
      this.$store.commit('setSnackbar', {
        show: false,
        time: -1,
        message: '',
        color: 'primary',
      });

      if (!this.initialized || this.$route.path === '/login') {
        return;
      }

      const online = this.discernNetStat();
      const timeLeft = this.resumeTimer ? this.resumeTimer.getTimeLeft() : 0;
      this.updateStatus(online);
      if (!online || timeLeft > 0) {
        this.resumeTimer = new Timer(() => {}, this.resumeWaitTimeInMS);
        return;
      }
      this.resumeTimer = new Timer(() => {}, this.resumeWaitTimeInMS);
      const res = await this.$store.dispatch('resume');
      if (res) {
        this.$store.commit('setSnackbar', {
          show: true,
          time: 1000,
          message: 'Refreshed Data',
          color: 'primary',
        });
        if (this.$route.name === 'Weekly') {
          this.checkEntriesForLastWeek();
        }
      }
    },
    missingAllWeekdayEntries() {
      const dates = this.$store.state.shared.dates;
      const timeForLastWeek = dates.last.map((d, i) => {
        if (i === 0 || i === 6 || !this.$store.getters.entries[d.date]) {
          return false;
        }
        return this.$store.getters.entries[d.date].entries.length > 0;
      });
      return timeForLastWeek.every((day) => {
        return day === false;
      });
    },
    missingBetweenOneAndFourWeekdayEntries() {
      const dates = this.$store.state.shared.dates;
      const timeForLastWeek = dates.last.map((d, i) => {
        if (i === 0 || i === 6 || !this.$store.getters.entries[d.date]) {
          return true;
        }
        return this.$store.getters.entries[d.date].entries.length > 0;
      });
      return timeForLastWeek.some((day) => {
        return day === false;
      });
    },
    checkEntriesForLastWeek() {
      // return array of bools for each day telling if it has entries or not.
      this.lastWeekModal = !this.$store.getters.isSalaried
      && (this.missingAllWeekdayEntries() || this.missingBetweenOneAndFourWeekdayEntries());
    },
  },
};
</script>
<style>
.v-toolbar__content{
  display: flex;
  justify-content: space-between;
  width:100%;
}
</style>
